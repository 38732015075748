<template>
  <v-container justify="center" align="center" fill-height>
    <v-card color="white" elevation="24" class="mx-auto rounded-lg primary--text pa-8">
      <v-row>
        <v-col cols="1" align="center" justify="center" align-self="center" fill-height>
          <v-row>
            <v-avatar v-if="userimage">
              <img src="https://randomuser.me/api/portraits/women/81.jpg" />
            </v-avatar>
            <v-avatar color="primary" v-if="!userimage">
              <span class="secondary--text font-weight-black">{{ avaterAlphabet }}</span>
            </v-avatar>
          </v-row>
        </v-col>
        <v-col cols="11" align="start" justify="center">
          <v-card-title class="font-weight-black">
            <span class="primary--text">{{ currentUser.displayName }}</span></v-card-title
          >
          <v-card-subtitle class="accent--text pb-0">{{ currentUser.email }}</v-card-subtitle>
          <v-card-subtitle class="accent--text py-0 my-0">
            Last Login at <b>{{ new Date(currentUser.lastLoginAt * 1000) }}</b></v-card-subtitle
          >
        </v-col>
        <v-col>
          <v-btn class="mr-2" color="primary" v-if="!currentUser.email" to="/guestsignup">
            <font-awesome-icon icon="user-plus" pull="left"></font-awesome-icon>
            Signup</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "Profile",
  data() {
    return {
      userimage: false,
      avaterAlphabet: null,
      drawer: true,
      items: [
        { title: "My Account", icon: "fas fa-user-circle", heading: "Account" },
        { title: "Quiz Settings", icon: "fas fa-cog", heading: "Settings" },
      ],
      toggle: "Account",
    }
  },

  created() {
    if (this.currentUser.photoURL == null) {
      this.avaterAlphabet = this.currentUser.displayName?.charAt(0) ?? "g"
    } else {
      this.userimage = true
    }
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  mounted() {},
}
</script>
